import {getAuth, signInWithPopup, OAuthProvider, onAuthStateChanged} from "firebase/auth";
import Encode from '../PayloadModify/encode'
import {jwtDecode} from 'jwt-decode';
import {UserInfo} from '../Firebase/Master'
import {initializeApp} from "firebase/app";
import Config from '../Firebase/Config'
import graphUrl from './photoUrl'
const app = initializeApp(Config);
const auth = getAuth(app)

const userInfo = new UserInfo();
const provider = new OAuthProvider('microsoft.com');

const Microsoft = () => {
    return new Promise(async(resolve, reject) => {
        signInWithPopup(auth, provider).then(async(result) => {
            const credential = OAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;
           // console.log(accessToken, credential)
            let dec = jwtDecode(credential.idToken);
          //  console.log(dec)
           
            let item  = {
                "email" : dec.email,
                "name" : dec.name ? dec.name: null,
                "exp" : dec.exp,
                "photo": dec.photoURL? dec.photoURL: null,
                "provider": "Microsoft"
            }
            
            onAuthStateChanged(auth, async(user) => {
                if (user) {
                    const uid = user.uid;
                    item.userId = uid;
                   // console.log(item)
                    let email = false;
                    let res = await userInfo.getUserInfo(user.uid);
                    if (res === null) 
                        {
                            await userInfo.createUser(item);
                            email = true;
                        }
                    
                    // console.log(item); await createUser(item).then(async res => {
                    // console.log(item)
                    resolve({
                        "username": "@" + item.name.replace(/ /g, '-'),
                        "token": await Encode(item),
                        "email" : email,
                        "item" : email ? item : null
                    })
                } else {}
            });
             
           
        }).catch((error) => {
            
            reject(error.message.replace('Firebase: ', ''))
        });

    })
}

export default Microsoft