import React from 'react';
import TokenDecoder from "../Provider/jwtDecoder";
import Signout from '../Provider/signout'
import {
    getDatabase,
    ref,
    update,
    push,
    child,
    remove,
    onValue,
    orderByChild,
    equalTo,
    query,
    orderByKey,
    startAt,
    endAt,
    limitToFirst,
    get,
    set
} from "firebase/database";
import Config from '../Firebase/Config'
import {initializeApp} from 'firebase/app';
import {useObjectVal,useListKeys} from 'react-firebase-hooks/database';

const app = initializeApp(Config);
const db = getDatabase(app);
const dbRef = ref(getDatabase());

const Activity = e => {
    const [isOpen,
        setIsOpen] = React.useState(false);
    const dropdownRef = React.useRef();
    const buttonRef = React.useRef();

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpen && dropdownRef.current && buttonRef.current && !dropdownRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        // Add the event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);
    const [setProfile,
        setsetProfile] = React.useState({})

    const verfiyToken = async(token) => {

        await TokenDecoder(token).then(async id => {
            setsetProfile(id);
        }).catch(err => {
            console.log(err)
            //reject(err.message || err)
        })
    }
    var [previewdata] = useObjectVal(ref(db,'User/' + e.userId + '/Notification'));
    //previewdata = previewdata.reverse()
    React.useEffect(() => {

        setTimeout(() => {
            if (localStorage.getItem(process.env.REACT_APP_MEZZO)) 
                verfiyToken(localStorage.getItem(process.env.REACT_APP_MEZZO))
            else 
                window.location.href = "/"
        }, 500)

    }, [])

    return (
        <div>
            <div class="flex items-center ms-3">
                <div>
                    <button type="button" ref={buttonRef} onClick={toggleDropdown}>
                        <span class="material-icons ">
                            notifications
                        </span>
                    </button>
                </div>

            </div>
            <div
                ref={dropdownRef}
                
                className={`overflow-auto ${isOpen
                ? "block"
                : "hidden"} h-96 w-4/12 absolute z-50 mt-6 sm:top-16 sm:right-10 lg:top-9 lg:right-20 top-16 right-4 my-4 text-base list-none bg-white divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600 `}>
                <div className="px-4 py-3 text-left">
                    <h1 className='text-white text-center border-b-2'>Notifications</h1>
                </div>
                <ul className='text-left p-3 text-white'>
                   {previewdata && Object.values(previewdata) && Object.values(previewdata).map(preview=>{
                    return <li>{preview.message}</li>
                   })}
                </ul>
            </div>
        </div>
    )
}

export default Activity