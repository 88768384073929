import CryptoJS from 'crypto-js';

const Encode = payload => {
    return new Promise(async(resolve, reject) => {
        try {
            resolve(CryptoJS.AES.encrypt(JSON.stringify(payload), process.env.REACT_APP_JWTSECRET).toString())
        } catch (err) {
      
            reject(err.message || err)
        }
    })
}

export default Encode