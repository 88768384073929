import {getAuth, signOut} from "firebase/auth";
import { initializeApp } from "firebase/app";
import Config from '../Firebase/Config'
const app = initializeApp(Config);
const auth = getAuth(app)


const Signout = () => {
    return new Promise(async(resolve, reject) => {
        signOut(auth).then(() => {
            localStorage.removeItem(process.env.REACT_APP_MEZZO)
            resolve('')
        }).catch((error) => {
              reject(error.message)
            // An error happened.
        });

    })
}

export default Signout