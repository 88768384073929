import CryptoJS from 'crypto-js';

const Decode = payload => {
    return new Promise(async(resolve, reject) => {
        try {
            const bytes = CryptoJS.AES.decrypt(payload.replace(/ /g,'+'), process.env.REACT_APP_JWTSECRET);
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            resolve(decryptedData);
        } catch (err) {
            console.log(err)
            reject(err.message || err)
        }
    })
}

export default Decode