import logo from './logo.svg';
import './App.css';
import Main from './Main/Main'
import React, {useEffect} from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Dashboard from './Dashboard/Dashboard';
function App() {

    return (
        <div className="App">
  <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/:userName" element={<Dashboard />} />
      </Routes>
    </Router>
        </div>
    );
}

export default App;
