import React, { useState, useRef, useEffect, lazy, Suspense } from "react";
import ProjectList from "../ProjectList/ProjectList";
import TokenDecoder from "../Provider/jwtDecoder";
import Loading from "../Loading/Loading";
import { Toaster } from "react-hot-toast";
import { UserInfo } from "../Firebase/Master";
import { useParams } from "react-router-dom";
import Profile from "./NavUser";
import Activity from "./Activity";
import Signout from "../Provider/signout";
import version from "../version.json";
import { Tooltip } from "react-tooltip";
const userDetails = new UserInfo();

var Service = lazy(() => import("../ProjectList/ProjectList"));
const Dashboard = (e) => {
  const { orgId, userName } = useParams();
  const [userId, setuserid] = React.useState();
  const [userInfo, setuserInfo] = React.useState();
  const verfiyToken = async (token) => {
    await TokenDecoder(token)
      .then(async (id) => {
        setuserid(id.userid);
        setuserInfo(id);
        if (userName !== "@" + id.name.replace(/ /g, "-").toLowerCase())
          window.location.href = "/";
        // await userDetails.getUserInfo(id.userid)     .then(item => {         if
        // (item.length == 0)             setuserInfo(id)         else
        // setuserInfo(item[0])     }) setTimeout(() => {     setLoadContent(false) },
        // 1000)
      })
      .catch((err) => {
        console.log(err);
        //reject(err.message || err)
      });
  };
  const [loadContent, setLoadContent] = React.useState(false);
  const [loadContentCount, setloadContentCount] = React.useState(0);
  const [showprojectinfo, setShowProjectinfo] = React.useState(false);
  React.useEffect(() => {
   // menuselect("Integration");
   menuselect("ProjectList");
    //setLoadContent(true)
    if (localStorage.getItem(process.env.REACT_APP_MEZZO))
      verfiyToken(localStorage.getItem(process.env.REACT_APP_MEZZO));
    else window.location.href = "/";
  }, []);
  const [selectContent, setSelectContent] = React.useState("");
  const menuselect = (e) => {
    if (isOpenNab) toggleSidebar();
    setShpwTemplate(e == "Template" ? true : false);
    setSelectContent(e);

    Service = lazy(() => import("../" + e + "/" + e));
  };
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();
  const buttonRef = useRef();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        dropdownRef.current &&
        buttonRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);
  const [showTemplate, setShpwTemplate] = React.useState(
    selectContent == "Template" ? true : false
  );
  const [projectId, setProjectId] = React.useState("");
  const logout = async (e) => {
    await Signout()
      .then(() => {
        window.location.href = "/";
      })
      .catch((err) => console.log(err));
  };
  const [isOpenNab, setIsOpenNab] = React.useState(false);

  const toggleSidebar = () => {
    setIsOpenNab(!isOpenNab);
  };
  const [integration, setIntegration] = React.useState("");
  return (
    <div>
      <div className="animate__animated animate__fadeIn">
        <nav class=" w-full mb-0 md:mb-10 pt-4 md:pt-8 pl-0 md:pl-52  p-4 sm:p-0">
          <div class=" lg:px-5 lg:pl-3">
            <div class="flex items-center justify-between">
              <div class="flex items-center justify-start rtl:justify-end">
                <button
                  type="button"
                  className="place-content-start flex p-2 sm:hidden"
                  onClick={toggleSidebar}
                >
                  <span class="sr-only">Open sidebar</span>
                  <svg
                    class="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                      d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                    ></path>
                  </svg>
                </button>
                <div className="sm:hidden block">
                  <nav class="flex pt-2 " aria-label="Breadcrumb">
                    <ol class="inline-flex text-black items-center font-extrabold space-x-1 md:space-x-2 rtl:space-x-reverse ">
                      <li class="inline-flex items-center">
                        <a
                          class="inline-flex items-center text-sm   pl-2 pb-2 font-bold "
                          onClick={(e) => {
                            setShowProjectinfo(false);
                            menuselect("ProjectList");
                            setProjectId("");
                          }}
                        >
                          {userName}/Home
                        </a>
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="hidden sm:block">
                  <nav class="flex pt-2 " aria-label="Breadcrumb">
                    <ol class="inline-flex text-black items-center font-extrabold space-x-1 md:space-x-2 rtl:space-x-reverse ">
                      <li class="inline-flex items-center">
                        <span class="material-icons">corporate_fare</span>
                        <a class="inline-flex items-center text-sm font-medium  pl-1">
                          {userName}
                        </a>
                      </li>

                      {showprojectinfo ? (
                        <li>
                          <li class="inline-flex items-center">
                            <svg
                              class="rtl:rotate-180 w-3 h-3 text-black font-extrabold mx-1"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 6 10"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="m1 9 4-4-4-4"
                              />
                            </svg>

                            <a
                              onClick={(e) => {
                                setShowProjectinfo(false);
                                menuselect("ProjectList");
                                setProjectId("");
                              }}
                              class="cursor-pointer inline-flex items-center text-sm font-medium pl-1    "
                            >
                              ProjectList
                            </a>
                          </li>
                          <li class="inline-flex items-center">
                            <svg
                              class="rtl:rotate-180 w-3 h-3 text-black font-extrabold mx-1"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 6 10"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="m1 9 4-4-4-4"
                              />
                            </svg>

                            <a
                              //href={`/Organization/${orgId}`}
                              class="inline-flex items-center text-sm font-medium pl-1    "
                            >
                              {/* {selectContent == "ProjectOverview"
                                                                ? projectId.replace(/%20/g, " ")
                                                                : selectContent} */}
                              {selectContent}
                            </a>
                          </li>

                        </li>
                      ) : (
                        <li class="inline-flex items-center">
                          <svg
                            class="rtl:rotate-180 w-3 h-3 text-black font-extrabold mx-1"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 6 10"
                          >
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="m1 9 4-4-4-4"
                            />
                          </svg>

                          <a
                            // href={`/Organization/${orgId}`}
                            class="inline-flex items-center text-sm font-medium pl-1    "
                          >
                            {selectContent}
                          </a>
                        </li>
                      )}
                    </ol>
                  </nav>
                </div>
              </div>
              {userId ? (
                <div class="flex items-center">
                  {/* <Activity userId={userId}/> */}
                  <Profile userId={userId} />
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </nav>

        <aside
          style={{ backgroundColor: "#211C19" }}
          id="logo-sidebar"
          className={`fixed top-0 left-0 z-40 w-64 md:w-48 pt-5 h-screen transition-transform ${
            isOpenNab ? "translate-x-0" : "-translate-x-full"
          } sm:translate-x-0`}
          aria-label="Sidebar"
        >
          <div class=" px-3 pb-4 overflow-y-auto  flex flex-col min-h-screen">
            <div className="flex justify-end place-content-end place-items-end">
              <span
                className="sm:hidden material-icons text-white float-right"
                onClick={toggleSidebar}
              >
                cancel
              </span>
            </div>

            <a href="/">
              <img
                src="/image/logo.png"
                className="h-12 md:h-15 sm:h-24 mx-auto"
                alt=" Logo"
              />
              <p className="text-center  text-md md:text-2xl font-semibold dark:text-white mb-8">
                Mezzo
              </p>
            </a>

            <ul class="space-y-2 font-medium mt-10">
              <li>
                <a
                  onClick={(e) => {
                    setShowProjectinfo(false);
                    menuselect("ProjectList");
                    setProjectId("");
                  }}
                  class={`flex cursor-pointer items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group `}
                  style={{
                    backgroundColor:
                      showprojectinfo || selectContent == "ProjectList"
                        ? "#F46D21"
                        : "",
                  }}
                >
                  <span class="material-icons">info</span>
                  <span class="ms-3">Projects</span>
                </a>
              </li>
              {showprojectinfo ? (
                <ul class="space-y-2 font-medium mt-10 ml-5">
                  <li>
                    <a
                      onClick={(e) => menuselect("ProjectOverview")}
                      class={`flex cursor-pointer items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group `}
                      style={{
                        backgroundColor:
                          showprojectinfo && selectContent == "ProjectOverview"
                            ? "#F46D21"
                            : "",
                      }}
                    >
                      <span class="material-icons">list</span>
                      <span class="ms-3">Overview</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={(e) => {
                        setShowProjectinfo(true);
                        menuselect("ProjectBoard");
                      }}
                      class={`flex cursor-pointer items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group `}
                      style={{
                        backgroundColor:
                          showprojectinfo && selectContent == "ProjectBoard"
                            ? "#F46D21"
                            : "",
                      }}
                    >
                      <span class="material-icons">dashboard</span>
                      <span class="ms-3">Board</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={(e) => {
                        setShowProjectinfo(true);
                        menuselect("Team");
                      }}
                      class={`flex cursor-pointer items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group `}
                      style={{
                        backgroundColor:
                          showprojectinfo && selectContent == "Team"
                            ? "#F46D21"
                            : "",
                      }}
                    >
                      <span class="material-icons">groups</span>
                      <span class="ms-3">Team</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={(e) => {
                        setShowProjectinfo(true);
                        menuselect("ProjectNote");
                      }}
                      class={`flex cursor-pointer items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group `}
                      style={{
                        backgroundColor:
                          showprojectinfo && selectContent == "ProjectNote"
                            ? "#F46D21"
                            : "",
                      }}
                    >
                      <span class="material-icons">edit_note</span>
                      <span class="ms-3">Notes</span>
                    </a>
                  </li>
                  <li>
                    <a
                      data-tooltip-id="timesheet-tooltip"
                      data-tooltip-content="Coming Soon"
                      class={`opacity-40 flex cursor-pointer items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group `}
                      style={{
                        backgroundColor:
                          showprojectinfo && selectContent == "TimeSheet"
                            ? "#F46D21"
                            : "",
                      }}
                    >
                      <span class="material-icons">date_range</span>
                      <span class="ms-3">Timesheet</span>
                    </a>
                    <Tooltip id="timesheet-tooltip" place="right" />
                  </li>
                </ul>
              ) : (
                ""
              )}

              <li>
                <a
                  onClick={logout}
                  class={`flex items-center cursor-pointer p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${
                    projectId &&
                    window.location.pathname.split("/")[4] === "Notes"
                      ? "bg-gray-700 "
                      : ""
                  } `}
                >
                  <span class="material-icons">logout</span>
                  <span class="ms-3">Logout</span>
                </a>
              </li>
            </ul>
            <p className="text-white text-xs pb-10 mt-auto text-center">
              2024
              <br />
              Created by Priyonuj Dey
              <br />
              <span className="text-xs pt-5">
                Under Development
                <br />
                <span>May Contain Bugs</span>
                <br />
                v-{version.fullVersion}
              </span>
            </p>
          </div>
        </aside>
        {/*   */}
        <div class="p-2 sm:ml-48 md:ml-48 ml-0">
          <div className=" ">
            <Suspense fallback={<div> Content is Loading ...</div>}>
              <Service
                userId={userId}
                userInfo={userInfo}
                projectId={projectId}
                integration={(e) => {
                  menuselect(e);
                  setIntegration(e);
                }}
                loadContentCount={loadContentCount}
                changeMenu={(e) => {
                  if (selectContent == "ProjectOverview") {
                    menuselect("ProjectList");
                    setProjectId("");
                    setShowProjectinfo(false);
                    setIntegration("");
                  }
                }}
                showprojectinfo={(e) => {
                  if (selectContent == "ProjectList") {
                    setShowProjectinfo(true);
                    setProjectId(e);
                    menuselect("ProjectOverview");
                    setIntegration("");
                  }
                }}
              />
            </Suspense>
          </div>
          <div>
            <Toaster />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
