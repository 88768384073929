

const SendEmail = async (prop) => {
  
      try {
     
        const raw = JSON.stringify(prop);
        
        const requestOptions = {
          method: "POST",
          headers:{ 
            'Content-Type': 'application/json',
            "access-key":  process.env.REACT_APP_APIM
          },
          body: raw,
          redirect: "follow"
        };
        fetch(process.env.REACT_APP_EMAILURL, requestOptions)
          .then((response) => response.text())
          .then((result) => console.log(result))
          .catch((error) => console.error(error));
      } catch (error) {
        console.log({ type: 'error', message: 'An error occurred while sending the email. ' + error.message});
      }
  
}
export default SendEmail