import React from 'react';
import { format, differenceInDays } from 'date-fns';
const DateRangeDisplay = ({ startDate, endDate }) => {
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return format(date, 'MMM d, yyyy');
    };
  
    const calculateDaysRemaining = () => {
      const today = new Date();
      const end = new Date(endDate);
      const daysRemaining = differenceInDays(end, today);
      return daysRemaining > 0 ? daysRemaining : 0;
    };
  
    const daysRemaining = calculateDaysRemaining();
  
    return (
      <div className="flex items-center space-x-2 text-xs">
        <div className="flex items-center space-x-1 bg-gray-100 rounded-full px-2 py-1">
          <span className="material-icons text-gray-500 text-xs">event</span>
          <span className="text-gray-700">
            {formatDate(startDate)} - {formatDate(endDate)}
          </span>
        </div>
        <div className={`flex items-center space-x-1 rounded-full px-2 py-1 ${
          daysRemaining > 5 ? 'bg-green-100 text-green-800' : 
          daysRemaining > 0 ? 'bg-yellow-100 text-yellow-800' : 'bg-red-100 text-red-800'
        }`}>
          <span className="material-icons text-xs">hourglass_empty</span>
          <span>
            {daysRemaining > 0 ? `${daysRemaining}d` : '0d'}
          </span>
        </div>
      </div>
    );
  };

  export default DateRangeDisplay