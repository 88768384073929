import {initializeApp} from 'firebase/app';
import Config from './Config'
import Helper from '../Helper/Helper';
import SendEmail from '../Email/SendEmail';
import {
    getDatabase,
    ref,
    update,
    push,
    child,
    remove,
    onValue,
    orderByChild,
    equalTo,
    query,
    orderByKey,
    startAt,
    endAt,
    limitToFirst,
    get,
    set
} from "firebase/database";
const app = initializeApp(Config);
const db = getDatabase(app);
const dbRef = ref(getDatabase());

const Notification = async(userId, message) => {

    return new Promise(async(resolve, reject) => {
        var pushkey = push(child(ref(db), process.env.REACT_APP_PATHUSER +"/" + userId + '/Notification/')).key;
        await update(ref(db, process.env.REACT_APP_PATHUSER +"/" + userId + '/Notification/' + pushkey), {"message": message})
        resolve("Created")
    })

}
class UserInfo {
    async createUser(item) {
        return new Promise(async(resolve, reject) => {
            item.createdDate = new Date();
            try {
               await update(ref(db, process.env.REACT_APP_PATHUSER +"/" + item.userId), item)
               await Notification(item.userId, "Welcome to Mezzo, your personal project management tool!")
                resolve("Created")
            } catch (err) {
                console.log(err);
                reject(err.message || err)
            }
        })
    }
    async getUserInfo(userId) {

        return new Promise(async(resolve, reject) => {
            await get(child(dbRef, process.env.REACT_APP_PATHUSER +"/" + userId)).then(async(snapshot) => {

                resolve(snapshot.val());
            }).catch(err => reject(err))
        })
    }

    async searchUserByEmail(searchText) {
        return new Promise(async(resolve, reject) => {

            try {
                const usersRef = ref(db, 'User');
                const searchQuery = query(usersRef, orderByChild('email'), startAt(searchText), endAt(searchText + "\uf8ff"));
                onValue(searchQuery, (snapshot) => {
                    resolve(snapshot.val())
                });
            } catch (err) {
                console.log(err);
                reject(err.message || err)
            }

        })

    }
}
class OrgListMaster {

    async Submit(item) {
        return new Promise(async(resolve, reject) => {
            item.createdDate = new Date();
            try {
                let orgD = await this.GetListById(item.userId);
                if (orgD) {
                    reject("Team/Organization Name already taken")
                } else {
                    await update(ref(db, 'Organization/' + item.orgId), item).then(async() => {
                        await update(ref(db, process.env.REACT_APP_PATHUSER +"/" + item.userId + '/Organization/' + item.orgId), {
                            "name": item.organization,
                            "orgId": item.orgId
                        })
                        var pushkey = push(child(ref(db), 'Organization/' + item.orgId + '/Member/Owner/' + item.userId)).key;
                        await update(ref(db, 'Organization/' + item.orgId + '/Member/Owner/' + pushkey), {
                            "userId": item.userId,
                            "createdDate": item.createdDate,
                            "type": "Owner"
                        })
                        resolve("Created")
                    })

                }
                //  var pushkey = push(child(ref(db), item.userId + '/Organization')).key;
            } catch (err) {
                console.log(err);
                reject(err.message || err)
            }
        })
    }
    async GetList(userId) {

        return new Promise(async(resolve, reject) => {
            await get(child(dbRef, process.env.REACT_APP_PATHTEAM +"/" + userId + '/Organization')).then(async(snapshot) => {

                resolve(snapshot.val());
            }).catch(err => reject(err))
        })

    }
    async GetListById(item) {
        return new Promise(async(resolve, reject) => {
            await get(child(dbRef, process.env.REACT_APP_PATHTEAM +"/" + item.userId + '/Organization/' + item.orgId)).then(async(snapshot) => {
                resolve(snapshot.val())
            }).catch(err => reject(err))
        })

    }
    async GetOrgNameById(orgId) {
        return new Promise(async(resolve, reject) => {
            await get(child(dbRef, 'Organization/' + orgId)).then(async(snapshot) => {
                resolve(snapshot.val().organization)
            }).catch(err => reject(err))
        })

    }
    async GetOrgmemberList(orgId) {
        const userInfo = new UserInfo();
        return new Promise(async(resolve, reject) => {
            var members;
            const membersSnapshot = await get(ref(db, 'Organization/' + orgId + '/Member'));
            members = membersSnapshot.val();
            for (const role in members) {
                const roleMembers = members[role];
                for (const memberId in roleMembers) {
                    const userSnapshot = await userInfo.getUserInfo(roleMembers[memberId].userId);
                    //  console.log(userSnapshot)
                    members[role][memberId].name = userSnapshot.name;
                    members[role][memberId].email = userSnapshot.email;
                    members[role][memberId].photo = userSnapshot.photo;
                }
            }
            resolve(members)
        })

    }
    async addorgmember(item) {
        return new Promise(async(resolve, reject) => {
            item.createdDate = new Date();
            let organization = await this.GetOrgNameById(item.orgId)
            // let role = []
            item
                .user
                .forEach(async user => {
                    console.log('Organization/' + item.orgId + '/Member/' + item.userRole)
                    await update(ref(db, process.env.REACT_APP_PATHUSER +"/" + user.userid + '/Organization/' + item.orgId), {
                        "name": organization,
                        "orgId": item.orgId
                    })
                    var pushkey = push(child(ref(db), 'Organization/' + item.orgId + '/Member/Owner/' + item.userId)).key;
                    await update(ref(db, 'Organization/' + item.orgId + '/Member/' + item.userRole + '/' + pushkey), {
                        "userId": user.userid,
                        "createdDate": item.createdDate,
                        "type": item.userRole
                    })
                    resolve("Created")
                })

        })
    }
}

class ProjectInfo {

    async Submit(item) {

        return new Promise(async(resolve, reject) => {
            item.createdDate = new Date();
            try {
                //  var pushkey = push(child(ref(db), item.userId + '/' + item.orgId +
                // '/'+ process.env.REACT_APP_PATHPROJECT+'/')).key;

                await update(ref(db, '/'+ process.env.REACT_APP_PATHPROJECT+'/' + item.id), item);
                let userInfo = new UserInfo();
                let user = await userInfo.getUserInfo(item.userId);
               
                // var pushkey = push(child(ref(db), '/'+ process.env.REACT_APP_PATHPROJECT+'/' + item.id+'/Owner')).key; await
                // update(ref(db, '/'+ process.env.REACT_APP_PATHPROJECT+'/' + item.id+'/'+process.env.REACT_APP_PATHTEAM +'/Owner/'+item.userId), {
                // "name" : user.name,     "email" : user.email,     "photo" : user.photo,
                // "userId" : item.userId, });
                await update(ref(db, '/'+ process.env.REACT_APP_PATHPROJECT+'/' + item.id + '/'+process.env.REACT_APP_PATHTEAM +'/' + item.userId), {
                    "name": user.name ? user.name : "USER",
                    "email": user.email,
                    "photo": user.photo ? user.photo : null,
                    "userId": item.userId,
                    "role": 'Owner'

                });
                await update(ref(db, process.env.REACT_APP_PATHUSER +"/" + item.userId + '/'+ process.env.REACT_APP_PATHPROJECT+'/' + item.id), {
                    "projectId": item.id,
                    "role": "Owner"
                })
                await Notification(item.userId, `Project ${item.title}  Added!`)
                // await update(ref(db,  '/'+ process.env.REACT_APP_PATHPROJECT+'/' + item.projectId+'/Owner/'+ item.userId),
                // user)
                resolve("Created")
                //  var pushkey = push(child(ref(db), item.userId + '/Organization')).key;

            } catch (err) {
                console.log(err);
                reject(err.message || err)
            }
        })
    }
    async GetOrgProjectNameById(orgId, projectId) {
        return new Promise(async(resolve, reject) => {
            await get(child(dbRef, 'Organization/' + orgId + '/'+ process.env.REACT_APP_PATHPROJECT+'/' + projectId)).then(async(snapshot) => {
                resolve(snapshot.val())
            }).catch(err => reject(err))
        })

    }
    async getAllProject(projectId) {

        return new Promise(async(resolve, reject) => {
            await get(child(dbRef, '/'+ process.env.REACT_APP_PATHPROJECT+'/' + projectId)).then(async(snapshot) => {
                resolve(snapshot.val())
            }).catch(err => reject(err))
        })
    }
    async GetList(userId) {
        return new Promise(async(resolve, reject) => {
            // await get(child(dbRef, process.env.REACT_APP_PATHTEAM +"/" + userId + '/Project')).then(async(snapshot)
            // => {     resolve(snapshot.val()); }).catch(err => reject(err))

            var projectList;
            const projectSnap = await get(ref(db, process.env.REACT_APP_PATHUSER +"/" + userId + '/'+process.env.REACT_APP_PATHPROJECT));
            projectList = projectSnap.val();
            for (const role in projectList) {
                const projId = projectList[role];
                const userSnapshot = await this.getAllProject(projId.projectId);
                projectList[projId.projectId] = userSnapshot;

            }

            resolve(projectList);
        })

    }
    async GetProjectInfo(item) {
        return new Promise(async(resolve, reject) => {

            let projectList;
            await get(child(dbRef, process.env.REACT_APP_PATHUSER +"/" + item.userId + "/"+ process.env.REACT_APP_PATHPROJECT+"/" + item.projectId)).then(async(snapshot) => {

                if (snapshot.exists) {
                    await get(child(dbRef, '/'+ process.env.REACT_APP_PATHPROJECT+'/' + item.projectId)).then(async(snapshot) => {
                        resolve(snapshot.val())
                    }).catch(err => reject(err))
                }
            }).catch(err => reject(err))

        })

    }
    async SubmitProjectTask(item) {
        return new Promise(async(resolve, reject) => {
            item.createdDate = new Date();
            try {
                // let pushkey; if(!item.id)     pushkey  = push(child(ref(db), process.env.REACT_APP_PATHTEAM +"/" +
                // item.assignedTo + '/Organization/'+item.orgId +'/'+ process.env.REACT_APP_PATHPROJECT+'/' +
                // item.projectId.replace(/ /g,'%20')+'/'+process.env.REACT_APP_PATHTASK+'/')).key; else pushkey  = item.id;
                // item.taskId = pushkey;
                await update(ref(db, process.env.REACT_APP_PATHPROJECT +'/' + item.projectId + '/'+process.env.REACT_APP_PATHTASK+'/' + item.id), item)
                // await update(ref(db, process.env.REACT_APP_PATHUSER +"/" + item.assignedTo + '/'+ process.env.REACT_APP_PATHPROJECT+'/' + item.projectId
                // + '/'+process.env.REACT_APP_PATHTASK+'/' + item.id), item)
               // await Notification(item.userId, `New Task Added!`)
                resolve("Created")
                //  var pushkey = push(child(ref(db), item.userId + '/Organization')).key;

            } catch (err) {
                console.log(err);
                reject(err.message || err)
            }
        })
    }
    async addProjectTeam(item) {
        return new Promise(async(resolve, reject) => {
            item.createdDate = new Date();
            try {
                await update(ref(db, process.env.REACT_APP_PATHUSER +"/" + item.assignedTo + '/'+ process.env.REACT_APP_PATHPROJECT+'/' + item.projectId + '/'+process.env.REACT_APP_PATHTASK+'/' + item.id), item)
                await Notification(item.userId, `Project Member  Added!`)
                resolve("Created")
                //  var pushkey = push(child(ref(db), item.userId + '/Organization')).key;

            } catch (err) {
                console.log(err);
                reject(err.message || err)
            }
        })
    }
    async GetOrgProjectTaskNameById(orgId, projectId, taskId) {
        return new Promise(async(resolve, reject) => {
            await get(child(dbRef, 'Organization/' + orgId + '/'+ process.env.REACT_APP_PATHPROJECT+'/' + projectId + '/'+process.env.REACT_APP_PATHTASK+'/' + taskId)).then(async(snapshot) => {
                resolve(snapshot.val())
            }).catch(err => reject(err))
        })

    }
    async GetTaskList(item) {

        return new Promise(async(resolve, reject) => {
            let projectTaskList;
            const userInfo = new UserInfo();
            try {
               // UserInfo userInfo = new UserInfo();
                let snapshot = await get(child(dbRef, process.env.REACT_APP_PATHPROJECT+"/" + item.projectId + '/'+process.env.REACT_APP_PATHTASK+'/'))
                let projectTaskList = snapshot.val()
               
                for(let i = 0; i < snapshot.size ;i++){
                     let userinfo= await userInfo.getUserInfo(Object.values(projectTaskList)[i]["assignedTo"])
                     Object.values(projectTaskList)[i]["name"] = userinfo.name;
                     Object.values(projectTaskList)[i]["photo"] = userinfo.photo;
                     Object.values(projectTaskList)[i]["email"] = userinfo.email
                }
                resolve(projectTaskList);
            } catch (err) {
                reject(err);
            }
        })

    }
    async RemoveProject(item) {

        return new Promise(async(resolve, reject) => {

            try {

                await remove(child(dbRef, process.env.REACT_APP_PATHUSER +"/" + item.userId + '/'+ process.env.REACT_APP_PATHPROJECT+'/' + item.projectId))
                await remove(child(dbRef, process.env.REACT_APP_PATHPROJECT+'/' + item.projectId));
                 await Notification(item.userId, `Project ${item.title}  Deleted!`)
                resolve("Project Deleted")
            } catch (err) {
                reject(err.message || err)
            }

        })

    }
    async RemoveTaskById(item) {

        return new Promise(async(resolve, reject) => {

            try {

                await remove(child(dbRef, process.env.REACT_APP_PATHPROJECT+'/' + item.projectId + '/'+process.env.REACT_APP_PATHTASK+'/' + item.id));
                await Notification(item.userId, `Project Task Deleted!`)
                resolve("Task Deleted")
            } catch (err) {
                console.log(err )
                reject(err.message || err)
            }

        })

    }
    async addProjectTeam(item) {
        return new Promise(async(resolve, reject) => {
            try {
              
                item.createdDate = new Date();
                let projectInfo = await this.getAllProject(item.projectId);
              
                item
                    .user
                    .forEach(async user => {

                        await update(ref(db, process.env.REACT_APP_PATHUSER +"/" + user.userId + '/'+ process.env.REACT_APP_PATHPROJECT+'/' + item.projectId), {"projectId": item.projectId})
                        user["role"] = item.userRole;
                        await update(ref(db, '/'+ process.env.REACT_APP_PATHPROJECT+'/' + item.projectId + '/'+process.env.REACT_APP_PATHTEAM +'/' + user.userId), user)
                        let email = {
                            "to" : user.email,
                            "subject" : "Welcome to the Project!",
                            "templateId" : "onboarding",
                            "parameters" : {
                                "assigneName" : user.name,
                                "project_name" : projectInfo.title,
                                "project_role" : item.userRole,
                                "project_start_date" : projectInfo.startDate,
                                "project_end_date" : projectInfo.endDate,
                                "user" : projectInfo.Team[item.createdBy].name,
                                "user_position" : projectInfo.Team[item.createdBy].role
                            }
                        }
                        SendEmail(email)
                        resolve("added")
                    })
            } catch (err) {
                reject(err.message || err)
            }

        })
    }
    async RemoveProjectTeam(item) {

        return new Promise(async(resolve, reject) => {

            try {

                await remove(child(dbRef, '/'+ process.env.REACT_APP_PATHPROJECT+'/' + item.projectId + '/'+process.env.REACT_APP_PATHTEAM+'/' + item.userId))
                await remove(child(dbRef, '/'+ process.env.REACT_APP_PATHUSER+'/' + item.userId + '/'+process.env.REACT_APP_PATHPROJECT+'/' + item.projectId))
                resolve("Project Note Deleted")
            } catch (err) {
                reject(err.message || err)
            }

        })

    }  
    async getTeamList(userId, projectId) {
        const userInfo = new UserInfo();
        return new Promise(async(resolve, reject) => {
            var members,
                memberList = [];
           

            let item = await get(ref(db, process.env.REACT_APP_PATHPROJECT+'/' + projectId + '/'+process.env.REACT_APP_PATHTEAM ))
            //members[userId]= roleName;
            resolve(item.val())
        })

    }
    async addProjectNote(item) {
        return new Promise(async(resolve, reject) => {
           // item.createdDate = new Date();
            try {
                await update(ref(db, '/'+ process.env.REACT_APP_PATHPROJECT+'/' + item.projectId + '/'+process.env.REACT_APP_PATHNOTE+'/' + item.id), item)
                resolve("Created")
                //  var pushkey = push(child(ref(db), item.userId + '/Organization')).key;

            } catch (err) {
                console.log(err);
                reject(err.message || err)
            }
        })
    }
    async getAllProjectNote(projectId) {

        return new Promise(async(resolve, reject) => {
            await get(child(dbRef, '/'+ process.env.REACT_APP_PATHPROJECT+'/' + projectId + '/'+process.env.REACT_APP_PATHNOTE+'/')).then(async(snapshot) => {
                resolve(snapshot.val())
            }).catch(err => reject(err))
        })
    }
    async RemoveProjectNote(item) {

        return new Promise(async(resolve, reject) => {

            try {

                await remove(child(dbRef, '/'+ process.env.REACT_APP_PATHPROJECT+'/' + item.projectId + '/'+process.env.REACT_APP_PATHNOTE+'/' + item.noteId))
                resolve("Project Note Deleted")
            } catch (err) {
                reject(err.message || err)
            }

        })

    }
    async addProjectBoardTab(item) {
        return new Promise(async(resolve, reject) => {
            item.createdDate = new Date();
            console.log(item)
            try {
                await set(ref(db, '/'+ process.env.REACT_APP_PATHPROJECT+'/' + item.projectId + '/BoardTab'), item.tabList)
                resolve("Created")
                //  var pushkey = push(child(ref(db), item.userId + '/Organization')).key;

            } catch (err) {
                console.log(err);
                reject(err.message || err)
            }
        })
    }
    async getProjectBoardTab(projectId) {

        return new Promise(async(resolve, reject) => {
            await get(child(dbRef, '/'+ process.env.REACT_APP_PATHPROJECT+'/' + projectId + '/BoardTab/')).then(async(snapshot) => {
                resolve(snapshot.val())
            }).catch(err => reject(err))
        })
    }

    async searchUserByEmail(item) {
        return new Promise(async(resolve, reject) => {
            try {
                const usersRef = ref(db, '/'+ process.env.REACT_APP_PATHPROJECT+'/' + item.projectId + "/Team");
                const searchQuery = query(usersRef, orderByChild('email'), startAt(item.searchText), endAt(item.searchText + "\uf8ff"));
                onValue(searchQuery, (snapshot) => {
                    resolve(snapshot.val())
                });
            } catch (err) {
                console.log(err);
                reject(err.message || err)
            }

        })

    }
    async countTotalMember(projectId) {

        return new Promise(async(resolve, reject) => {
            await get(child(dbRef, '/'+ process.env.REACT_APP_PATHPROJECT+'/' + projectId + '/'+process.env.REACT_APP_PATHTEAM +'/')).then(async(snapshot) => {
                resolve(snapshot.size)
            }).catch(err => reject(err))
        })
    }
    async countTotalTask(projectId) {
        let taskCount = []
        return new Promise(async(resolve, reject) => {
            await get(child(dbRef, '/'+ process.env.REACT_APP_PATHPROJECT+'/' + projectId + '/'+process.env.REACT_APP_PATHTASK+'/')).then(async(snapshot) => {
                let tab = await this.getProjectBoardTab(projectId);
                taskCount["totalTask"] = snapshot.size;
                const statusCounts = countTasksByStatusList(snapshot.exists() ? snapshot.val() : 0 , tab ? tab : Helper.boardDefaultTab);
                taskCount["other"] = statusCounts;
                resolve(taskCount)
            }).catch(err => reject(err))
        })
    }
    async updateProjectStatus(item) {
        let taskCount = []
        return new Promise(async(resolve, reject) => {
            try{
                await update(ref(db, '/'+ process.env.REACT_APP_PATHPROJECT+'/' + item.projectId + '/'+process.env.REACT_APP_PATHTASK+'/'+item.id), {
                    "status" : item.status
                })
                resolve("Updated project status")
            }
            catch(err){
                   reject(err.message || err)
            }
        })
    }
}



function countTasksByStatusList(tasks, statusList) {
    const counts = Object.values(statusList).reduce((acc, status) => {
        acc[status] = {
            status: status,
            count: 0
        };
        return acc;
    }, {});

    // Count tasks
    Object.values(tasks).forEach(task => {
        if (counts.hasOwnProperty(task.status)) {
            counts[task.status].count++;
        }
    });

    return counts;
}


export {OrgListMaster, ProjectInfo, UserInfo}