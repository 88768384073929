

import Decode from '../PayloadModify/decode'
const TokenDecoder = (token) => {
    return new Promise(async(resolve, reject) => {
       const decodedToken = await Decode(token);
        let currentDate = new Date();
    //     console.log(decodedToken.exp * 1000)
    //     console.log(currentDate)
    //     console.log(currentDate.getTime())
    //     currentDate.setHours(currentDate.getHours() + 1);
    //     console.log(currentDate)
    //    if (decodedToken.exp * 1000 < currentDate.getTime()) {
    //        if(localStorage.getItem('mezzo_currentSessionTime')){

    //        }
    //    }

        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            localStorage.removeItem(process.env.REACT_APP_MEZZO);
            localStorage.setItem('message' , "Session Expired")
            window.location.href= "/"
            reject("Session Exired")
        } else {
           
           let userinfo = {
            "userid" : decodedToken.userId,
            "name" : decodedToken.name,
            "email" : decodedToken.email,
            "photo" : decodedToken.photo,
           "timeLeft" :  decodedToken.exp * 1000 - currentDate.getTime(),
           "expireTime" : decodedToken.exp * 1000
           }
          // console.log(userinfo);
           resolve(userinfo);
        }
    })
}

export default TokenDecoder