var board = {
    "Upcoming": "#212130",
    "On-Progress": "#6B6103 ",
    "On-Hold": "#430303",
    "Completed": "#044817"
}
const boardDefaultTab = {
    "Tab-1": "Upcoming",
    "Tab-2": "On-Progress",
    "Tab-3": "On-Hold",
    "Tab-4": "Completed"
}

const status = ["Upcoming", "On-Progress", "On-Hold", "Completed"]

const projectColor = {
    "Upcoming": "#BEDBFD",
    "On-Progress": "#FDFCBE ",
    "On-Hold": "#FDBEBE",
    "Completed": "#D0FDBE"
}
const Modal_project_task = {
    content: {
        border: '0',
        borderRadius: '4px',
        bottom: 'auto',
        minHeight: '10rem',
        maxHeight: '40em',
        left: '50%',
        padding: '2rem',
        position: 'fixed',
        right: 'auto',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        minWidth: '20rem',
        width: window.innerWidth < 800
            ? '100%'
            : '40%',
        maxWidth: '40rem'
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    }
};
const Modal_project = {
    content: {
        border: '0',
        borderRadius: '4px',
        bottom: 'auto',
        minHeight: '10rem',
        maxHeight: '40em',
        left: '50%',
        padding: '2rem',
        position: 'fixed',
        right: 'auto',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        minWidth: '20rem',
        width: window.innerWidth < 800
            ? '100%'
            : '50%',
        maxWidth: '50rem'
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    }
};
const Modal_addOrgmember = {
    content: {
        border: '0',
        borderRadius: '4px',
        bottom: 'auto',
        minHeight: '10rem',
        maxHeight: '40em',
        left: '50%',
        padding: '2rem',
        position: 'fixed',
        right: 'auto',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        minWidth: '20rem',
        width: window.innerWidth < 800
            ? '100%'
            : '50%',
        maxWidth: '30rem'
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    }
};

const Associatewith_data = [
    {
        "id": "Self Project",
        "Title": ""
    }, {
        "id": "Client Project",
        "Title": "Company/Client Name"
    }, {
        "id": "School/College Project",
        "Title": "School/College Name"
    }
]
const formToJSON = (elements) => Array
    .from(elements)
    .reduce((data, element) => {
        if (element.name) { // Check if the element has a name attribute
            data[element.name] = element.value;
        }
        return data;
    }, {});

const defaultBoardList = [
    {
        "id": "Tab-1",
        "status": "Upcoming",
        "items": []
    }, {
        "id": "Tab-2",
        "status": "On-Progress",
        "items": []
    }, {
        "id": "Tab-3",
        "status": "On-Hold",
        "items": []
    }, {
        "id": "Tab-4",
        "status": "Completed",
        "items": []
    }
]

const emailTemplate = {
    0 : ``,
    1 : ``
}

export default {
    Modal_project,
    board,
    Associatewith_data,
    projectColor,
    Modal_addOrgmember,
    Modal_project_task,
    formToJSON,
    boardDefaultTab,
    defaultBoardList
}
