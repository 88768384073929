import React, { useState, useEffect, useRef } from 'react';
import TokenDecoder from "../Provider/jwtDecoder";
import Signout from '../Provider/signout';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const Profile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [profile, setProfile] = useState({});
  const dropdownRef = useRef();
  const buttonRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && dropdownRef.current && buttonRef.current && !dropdownRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem(process.env.REACT_APP_MEZZO)) {
        verifyToken(localStorage.getItem(process.env.REACT_APP_MEZZO));
      } else {
        window.location.href = "/";
      }
    }, 500);
  }, []);

  const verifyToken = async (token) => {
    try {
      const id = await TokenDecoder(token);
      setProfile(id);
    } catch (err) {
      console.log(err);
    }
  };

  const logout = async () => {
    try {
      await Signout();
      window.location.href = "/";
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 focus:outline-none"
        aria-expanded={isOpen}
        aria-haspopup="true"
      >
        <img
          className="w-10 h-10 rounded-full object-cover border-2 border-indigo-500 transition-transform duration-300 transform hover:scale-110"
          src={profile.photo || "/image/user.jpeg"}
          onError={(e) => { e.target.onerror = null; e.target.src = '/image/user.jpeg'; }}
          alt="User avatar"
        />
        <span className="hidden md:block text-gray-700 font-medium">{profile.name}</span>
        <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </button>

     

      {isOpen && (
        <div
          ref={dropdownRef}
          className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-xl z-50 transition-all duration-300 ease-in-out transform origin-top-right"
        >
          <div className="p-4 border-b border-gray-200">
            <p className="text-sm font-semibold text-gray-700">{profile.name}</p>
            <p className="text-xs text-gray-500">{profile.email}</p>
          </div>
          <ul className="py-2">
            <li>
              <a
               
                className="cursor-pointer text-left opacity-50 block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-50 transition-colors duration-150 ease-in-out"
              >
                <i className="fas fa-user-circle mr-2"></i> View Profile (Coming soon)
              </a>
            </li>
            
            <li>
              <button
                onClick={logout}
                className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-red-50 transition-colors duration-150 ease-in-out"
              >
                <i className="fas fa-sign-out-alt mr-2"></i> Log out
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Profile;