import React, { useEffect, useState } from "react";
import { toast } from 'react-hot-toast';
import SendEmail from '../Email/SendEmail';
import Google from '../Provider/Google';
import Microsoft from '../Provider/Microsoft';
import Signout from '../Provider/signout';
import Loading from "../Loading/Loading";
import TokenDecoder from "../Provider/jwtDecoder";

export default function Home() {
  const [accountSetting, setAccountSetting] = useState(false);
  const [loadingText, setLoadingText] = useState("Setting up your account");
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem('message')) {
        toast(localStorage.getItem('message'));
        localStorage.removeItem('message');
      }
    }, 100);
    setTimeout(() => {
      setShowContent(true);
    }, 1000);
  }, []);

  const redirect = (data) => {
    localStorage.setItem(process.env.REACT_APP_MEZZO, data.token);
    window.location.href = "/" + data.username.toLowerCase();
  };

  const handleLoginClick = async (provider) => {
    setShowContent(false);
    try {
      const data = await (provider === 'google' ? Google() : Microsoft());
      if (data.email) {
        const email = {
          to: data.item.email,
          subject: "Welcome to Mezzo - Project Management Tool!",
          templateId: "welcome",
          parameters: {
            name: data.item.name || "USER",
          }
        };
        setAccountSetting(true);
        await SendEmail(email);
        setTimeout(() => {
          setLoadingText("Account Created");
          redirect(data);
        }, 2500);
      } else {
        redirect(data);
        setShowContent(true);
      }
    } catch (err) {
      console.log(err);
      setShowContent(true);
    }
  };

  const logout = async () => {
    try {
      await Signout();
      window.location.href = "/";
    } catch (err) {
      console.log(err);
    }
  };

  const verifyToken = async (token) => {
    try {
      const id = await TokenDecoder(token);
      setTimeout(() => {
        window.location.href = "@" + id.name.replace(/ /g, '-').toLowerCase();
      }, 400);
    } catch (err) {
      console.log(err);
      setShowContent(true);
    }
  };

  const redirectDashboard = () => {
    setShowContent(false);
    if (localStorage.getItem(process.env.REACT_APP_MEZZO)) {
      verifyToken(localStorage.getItem(process.env.REACT_APP_MEZZO));
    } else {
      window.location.href = "/";
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white">
    <div className="container mx-auto px-4 py-16">
      {!showContent ? (
        <div className="text-center">
          <Loading />
          {accountSetting && (
            <div className="mt-8">
              <h2 className="text-2xl font-bold mb-2">
                {loadingText}
                <span className="inline-block w-6 animate-pulse">...</span>
              </h2>
              <p className="text-gray-400">Please wait while we prepare everything for you.</p>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="animate__animated animate__fadeIn text-center mb-16">
            <img src="image/logo.png" alt="Mezzo Logo" className="w-24 mx-auto mb-8" />
            <h1 className="text-4xl md:text-8xl font-extrabold mb-6">
              Mezzo
              <span className="block text-2xl md:text-6xl text-indigo-400 mt-2">Project Management Tool</span>
            </h1>
            <p className="text-xl md:text-2xl font-bold italic mb-12 text-gray-300">
              "Your Ultimate Project Management Solution"
            </p>

            {localStorage.getItem(process.env.REACT_APP_MEZZO) ? (
              <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
                <button
                  onClick={redirectDashboard}
                  className="flex items-center justify-center bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                >
                  <span className="material-icons mr-2">corporate_fare</span>
                  Dashboard
                </button>
                <button
                  onClick={logout}
                  className="flex items-center justify-center bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                >
                  <span className="material-icons mr-2">logout</span>
                  Sign Out
                </button>
              </div>
            ) : (
              <div className="flex flex-col sm:flex-row items-center justify-center gap-6">
                <button
                  onClick={() => handleLoginClick('microsoft')}
                  className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                >
                  <img src="image/microsoft.png" alt="Microsoft" className="w-6 h-6 mr-2" />
                  Sign in with Microsoft
                </button>
                <button
                  onClick={() => handleLoginClick('google')}
                  className="flex items-center justify-center bg-white hover:bg-gray-100 text-gray-800 font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                >
                  <img src="image/google.png" alt="Google" className="w-6 h-6 mr-2" />
                  Sign in with Google
                </button>
              </div>
            )}
          </div>

          {/* New content section */}
          <div className="max-w-6xl mx-auto mt-20">
            <h2 className="md:text-4xl text-xl font-bold text-center mb-12">Features </h2>
            <div className="grid md:grid-cols-3 gap-8">
              <FeatureCard
                icon="add_circle"
                title="Create Projects"
                description="Easily set up and organize multiple projects to keep your work structured."
              />
              <FeatureCard
                icon="dashboard"
                title="Interactive Task Board"
                description="Visualize your workflow with a dynamic board for task management."
              />
              <FeatureCard
                icon="tune"
                title="Customizable Board Tabs"
                description="Tailor your board layout with custom tabs to match your unique workflow."
              />
              <FeatureCard
                icon="drag_indicator"
                title="Drag and Drop Interface"
                description="Effortlessly move tasks between stages with an intuitive drag-and-drop system."
              />
              <FeatureCard
                icon="group_add"
                title="Team Collaboration"
                description="Invite team members to collaborate on projects and assign tasks efficiently."
              />
              <FeatureCard
                icon="note_add"
                title="Project Notes"
                description="Capture important details and ideas with built-in note-taking functionality."
              />
              <FeatureCard
                icon="assignment"
                title="Intuitive Task Management"
                description="Easily create, assign, and track tasks to keep your projects on schedule."
              />
             
              <FeatureCard
                icon="insert_chart"
                title="Insightful Analytics"
                description="Gain valuable insights with comprehensive project analytics and reporting."
              />
               <FeatureCard 
                  icon="access_time"
                  title="Timesheet"
                  description="Track time spent on tasks and projects for better resource management ."
                  comingSoon={true}
                  />
            </div>
          </div>

             {/* Footer section with deployment note and creator info */}
      <footer className=" mt-20">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-center md:text-left mb-4 md:mb-0">
  <p className="text-yellow-400 font-semibold">
    Note: Site under development
  </p>
  <p className="text-sm text-gray-300 mt-1">
    We're actively improving. Some features may be limited or change.
  </p>
  <p className="text-sm text-gray-300 mt-1">
    Thank you for your patience as we enhance your experience.
  </p>
</div>
            <div className="text-center md:text-right">
              <p className="text-gray-400">
                Created by <span className="font-semibold text-indigo-400"><a href="https://priyonujdy.in">Priyonuj Dey</a></span>
              </p>
            </div>
          </div>
        </div>
      </footer>
        </>
      )}
    </div>
  </div>
  );
}

const FeatureCard = ({ icon, title, description, comingSoon = false }) => (
  <div className="bg-gray-800 p-6 rounded-lg shadow-lg transform transition duration-300 hover:scale-105 relative">
    <div className="text-indigo-400 text-4xl mb-4">
      <span className="material-icons">{icon}</span>
      
    </div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-400">{description}</p>
    {comingSoon && (
      <div className="absolute top-3 right-3 flex items-center justify-center">
        <div className="relative">
          <div className="absolute inset-0 bg-yellow-400 rounded-full  opacity-75"></div>
          <div className="relative bg-yellow-500 text-gray-900 text-xs font-bold px-2 py-1 rounded-full z-10">
            Coming Soon
          </div>
        </div>
      </div>
    )}
  </div>
);