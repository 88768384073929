"use client"
import {jwtDecode} from 'jwt-decode';
import {getAuth, signInWithPopup, GoogleAuthProvider} from "firebase/auth";
import {initializeApp} from "firebase/app";
import Config from '../Firebase/Config'
import Encode from '../PayloadModify/encode'
import {UserInfo} from '../Firebase/Master'

const app = initializeApp(Config);
const auth = getAuth(app)
const provider = new GoogleAuthProvider();
const userInfo = new UserInfo();

const Google = () => {
    return new Promise(async(resolve, reject) => {
        signInWithPopup(auth, provider).then(async(result) => {
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const user = result.user;

            let item = {
                "userId": user.uid,
                "name": user.displayName
                    ? user.displayName
                    : null,
                "email": user.email,
                "provider": "Google",
                "photo": user.photoURL? user.photoURL: null,
                "exp" : (new Date(new Date().getTime() + 24 * 60 * 60 * 1000)).getTime()
            }
       
           let res = await userInfo.getUserInfo(user.uid);
           let email = false;
           if (res === null) 
            {
                await userInfo.createUser(item);
                email = true;
            }
        
        // console.log(item); await createUser(item).then(async res => {
        // console.log(item)
        resolve({
            "username":"@" +  item.name.replace(/ /g, '-'),
            "token": await Encode(item),
            "email" : email,
            "item" : email ? item : null
        })
           // console.log(item); await createUser(item).then(async res => {
           // console.log(item)
            //}).catch(err => {
              //  console.log(err)
            //   reject(err)
           // });
            // await apiRequest("userExistServer", "POST", payload).then(async res => {
            //     if (Object.values(res.data).length == 0) {
            //         let data = {
            //             "payload": 1,
            //             "userid": user.uid,
            //             "name": user.displayName
            //                 ? user.displayName
            //                 : null,
            //             "email": user.email,
            //             "provider": "Google",
            //             "photo": user.photoURL
            //                 ? user.photoURL
            //                 : null,
            //             "createdDate": new Date()
            //         }
            //         await apiRequest("addUserServer", "POST", data).then(item => {
            //             console.log(item)
            //         }).catch(err => {
            //             console.log(err)
            //         })
            //     }
            //     resolve(user.accessToken)
            // }).catch(err => {
            //     console.log(err)
            //     reject(err.message)
            // })
             
        }).catch((error) => {
            console.log(error)
            // Handle Errors here. const errorCode = error.code; const errorMessage =
            // error.message; // The email of the user's accoaunt used. const email =
            // error.customData.email; // The AuthCredential type that was used. const
            // credential = GoogleAuthProvider.credentialFromError(error);
            // console.log(error)
            reject(error.message.replace('Firebase: ', ''))
        });

    })
}

export default Google