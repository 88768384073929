import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { v4 as uuidv4 } from 'uuid';
import Helper from '../Helper/Helper';

const formToJSON = (elements) => Array.from(elements).reduce((data, element) => {
  if (element.name) {
    data[element.name] = element.value;
  }
  return data;
}, {});

const Addproject = prop => {
  const [associateWith, setAssociateWith] = useState(prop.projInfo ? prop.projInfo.associateWith || "0" : "0");
  const [startDate, setStartDate] = useState(prop.projInfo ? prop.projInfo.startDate || "" : "");

  useEffect(() => {
    setTimeout(() => {
      try {
        const yourRole = document.getElementById('yourrole');
        const customRoleInput = document.getElementById('customRoleInput');
        const customRoleValue = document.getElementById('custonRoleValue');

        if (yourRole) {
          yourRole.addEventListener('change', function () {
            if (this.value === 'other') {
              customRoleInput.style.display = 'block';
              customRoleValue.focus();
              customRoleValue.required = true;
            } else {
              customRoleInput.style.display = 'none';
              customRoleValue.required = false;
            }
            customRoleValue.value = "";
          });
        }
      } catch (err) {
        console.error(err);
      }
    }, 100);
  }, []);

  const submit = async e => {
    e.preventDefault();
    const form = document.getElementById('project-data');
    let data = formToJSON(form.elements);
    data.userId = prop.userId;
    data.id = prop.projInfo.id || `MZ-${uuidv4().slice(0, 8)}-${uuidv4().slice(9, 16)}`;
    prop.submitData(data);
    prop.close();
  };

  const inputClassName = "mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm";

  return (
    <Modal
      isOpen={prop.open}
      onRequestClose={prop.close}
      ariaHideApp={false}
      style={Helper.Modal_project}
      contentLabel="Add/Edit Project"
      className="fixed inset-0 flex items-center justify-center"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg shadow-xl p-8 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-800">
            {prop.edit ? "Edit Project" : "Add New Project"}
          </h2>
          <button onClick={prop.close} className="text-gray-500 hover:text-gray-700">
            <span className="material-icons">close</span>
          </button>
        </div>

        <form id="project-data" onSubmit={submit} className="space-y-6">
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title {!prop.showInfo && <span className="text-red-600">*</span>}</label>
            {!prop.showInfo ? (
              <input
                type="text"
                id="title"
                name="title"
                defaultValue={prop.projInfo.title || prop.projInfo.name}
                className={inputClassName}
                placeholder="Project Title"
                required
              />
            ) : (
              <p className="mt-1 text-sm text-gray-900 p-2 bg-gray-100 rounded-md">{prop.projInfo.title || prop.projInfo.name}</p>
            )}
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">Project Description</label>
            {!prop.showInfo ? (
              <textarea
                id="description"
                name="description"
                defaultValue={prop.projInfo.description}
                className={`${inputClassName} h-24`}
                placeholder="Project Description"
              />
            ) : (
              <p className="mt-1 text-sm text-gray-900 p-2 bg-gray-100 rounded-md">{prop.projInfo.description}</p>
            )}
          </div>

          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">Start Date</label>
              {!prop.showInfo ? (
                <input
                  type="date"
                  id="startDate"
                  name="startDate"
                  onChange={e => setStartDate(e.target.value)}
                  defaultValue={prop.projInfo.startDate}
                  className={inputClassName}
                  required
                />
              ) : (
                <p className="mt-1 text-sm text-gray-900 p-2 bg-gray-100 rounded-md">{prop.projInfo.startDate}</p>
              )}
            </div>

            <div>
              <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">End Date</label>
              {!prop.showInfo ? (
                <input
                  type="date"
                  id="endDate"
                  name="endDate"
                  disabled={!startDate}
                  min={startDate}
                  defaultValue={prop.projInfo.endDate}
                  className={inputClassName}
                  required
                />
              ) : (
                <p className="mt-1 text-sm text-gray-900 p-2 bg-gray-100 rounded-md">{prop.projInfo.endDate}</p>
              )}
            </div>
            
          <div>
            <label htmlFor="associateWith" className="block text-sm font-medium text-gray-700">Project Type</label>
            {!prop.showInfo ? (
              <select
                id="associateWith"
                name="associateWith"
                defaultValue={prop.projInfo.associateWith}
                onChange={e => setAssociateWith(e.target.value)}
                className={inputClassName}
              >
                <option value="0">Self-Project</option>
                <option value="1">Client/Company Project</option>
              </select>
            ) : (
              <p className="mt-1 text-sm text-gray-900 p-2 bg-gray-100 rounded-md">
                {prop.projInfo.associateWith === "0" ? "Self-Project" : "Client/Company Project"}
              </p>
            )}
          </div>

          <div>
            <label htmlFor="status" className="block text-sm font-medium text-gray-700">Status</label>
            {!prop.showInfo ? (
              <select
                id="status"
                name="status"
                defaultValue={prop.projInfo.status}
                className={inputClassName}
              >
                <option value="Upcoming">Upcoming</option>
                <option value="In-Progress">In-Progress</option>
                <option value="Completed">Completed</option>
                <option value="Live">Live</option>
                <option value="On-Hold">On-Hold</option>
              </select>
            ) : (
              <p className="mt-1 text-sm text-gray-900 p-2 bg-gray-100 rounded-md">{prop.projInfo.status}</p>
            )}
          </div>
          </div>


          {associateWith === "1" && (
            <div>
              <label htmlFor="clientSchoolCollegeName" className="block text-sm font-medium text-gray-700">Associate With (Optional)</label>
              {!prop.showInfo ? (
                <input
                  type="text"
                  id="clientSchoolCollegeName"
                  name="clientSchoolCollegeName"
                  defaultValue={prop.projInfo.clientSchoolCollegeName}
                  className={inputClassName}
                  placeholder={Helper.Associatewith_data[associateWith].description}
                />
              ) : (
                <p className="mt-1 text-sm text-gray-900 p-2 bg-gray-100 rounded-md">{prop.projInfo.clientSchoolCollegeName}</p>
              )}
            </div>
          )}

          {!prop.showInfo && (
            <div className="flex justify-end space-x-3 pt-5">
              <button
                type="button"
                onClick={prop.close}
                className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {prop.edit ? "Update Project" : "Create Project"}
              </button>
            </div>
          )}
        </form>
      </div>
    </Modal>
  );
};

export default Addproject;