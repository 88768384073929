import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import TokenDecoder from "../Provider/jwtDecoder";
import { ProjectInfo } from "../Firebase/Master";
import Addproject from "./Addproject";
import ProjectLoading from "../Loading/ProjectLoading";
import DateRangeDisplay  from "../Daterange/DateRangeDisplay";
const projectInfo = new ProjectInfo();

const ProjectList = (prop) => {
  const { orgId } = useParams();
  const [userId, setUserId] = useState(prop.userId);
  const [projectList, setProjectList] = useState({});
  const [selectDiv, setSelectDiv] = useState('all');
  const [loadContent, setLoadContent] = useState(false);
  const [addproject, setAddProject] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [showInfo, setShowInfo] = useState(false);
  const [projInfo, setProjInfo] = useState([]);
  const [selectProj, setSelectProj] = useState("0");

  useEffect(() => {
    setLoadContent(true);
    if (localStorage.getItem(process.env.REACT_APP_MEZZO))
      verifyToken(localStorage.getItem(process.env.REACT_APP_MEZZO));
    else
      window.location.href = "/";
  }, []);

  const verifyToken = async (token) => {
    try {
      const id = await TokenDecoder(token);
      setUserId(id.userid);
      getUserProjectList(prop.userId);
    } catch (err) {
      console.log(err);
    }
  };

  const getUserProjectList = async (e) => {
    try {
      const data = await projectInfo.GetList(prop.userId);
      if (data) {
        const sortedData = Object.values(data).sort((a, b) => b.startDate - a.startDate);
        setProjectList(sortedData);
      }
      setLoadContent(false);
    } catch (error) {
      console.error("Error fetching project list:", error);
      setLoadContent(false);
    }
  };

  const submitProject = async (data) => {
    toast.promise(
      projectInfo.Submit(data).then(() => {
        setProjectList((prevList) => {
          // Ensure prevList is an array
          const list = Array.isArray(prevList) ? prevList : [];
          
          if (!edit) {
            // Add new project
            return [...list, data];
          } else {
            // Update existing project
            return list.map((project) => 
              (project.id === data.id ? { ...project, ...data } : project)
            );
          }
        });
        
        return edit
          ? `${data.title} updated successfully`
          : `${data.title} created successfully`;
      }),
      {
        loading: edit ? 'Updating project...' : 'Creating project...',
        success: (message) => message,
        error: (err) => `Error: ${err.message}`,
      }
    );
  };


  const showProjectDetail = (e) => {
    delete e.Team;
    delete e.Task;
    console.log(e)
    setEdit(true);
    setEditData(e);
    setAddProject(true);
  };

  const getStatusColor = (status) => {
    const colors = {
      'Upcoming': 'bg-blue-100 text-blue-800',
      'In-Progress': 'bg-yellow-100 text-yellow-800',
      'Completed': 'bg-green-100 text-green-800',
      'Live': 'bg-purple-100 text-purple-800',
      'On-Hold': 'bg-red-100 text-red-800',
    };
    return colors[status] || 'bg-gray-100 text-gray-800';
  };

  return (
    <div className=" px-4 ">
      {loadContent ? (
        <ProjectLoading />
      ) : (
        <>
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-xl font-bold text-gray-800 hidden md:block  ">Projects</h1>
            <div className="flex space-x-4">
              <select
                className="bg-white border border-gray-300 text-gray-700 py-2 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                onChange={(e) => setSelectProj(e.target.value)}
              >
                <option value="0">All Projects</option>
                <option value="Upcoming">Upcoming</option>
                <option value="In-Progress">In Progress</option>
                <option value="Completed">Completed</option>
                <option value="Live">Live</option>
                <option value="On-Hold">On Hold</option>
              </select>
              <button
                onClick={() => setAddProject(true)}
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out flex items-center"
              >
                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                </svg>
                Add Project
              </button>
            </div>
          </div>

          {Object.values(projectList).length === 0 || Object.values(projectList).filter(proj => selectProj === "0" || proj.status === selectProj).length === 0 ? (
            <div className="text-center py-12">
              <img src="/image/undraw_add_notes_re_ln36.svg" className="w-64 mx-auto mb-6" alt="No projects" />
              <p className="text-xl font-semibold text-gray-600">No projects found. Add a new project to get started!</p>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              {Object.values(projectList)
                .filter(proj => selectProj === "0" || proj.status === selectProj)
                .map((data, index) => (
                  <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-xl">
                    <div className="p-6">
                      <div className="flex items-center mb-4">
                        <img src="/image/Designer.png" className="w-8 h-8 mr-3" alt="Project icon" />
                        <h2 className="text-md font-bold text-gray-800 cursor-pointer" onClick={() => prop.showprojectinfo(data.projectId || data.id)}>
                          {data.title || data.name}
                        </h2>
                      </div>
                      <DateRangeDisplay 
                                  startDate={data.startDate}
                                  endDate={data.endDate}
                                />
                      <div className="flex justify-between items-center mt-5">
                        <span className={`px-3 py-1 rounded-full text-xs font-semibold ${getStatusColor(data.status)}`}>
                          {data.status}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}

          {addproject && (
            <Addproject
              open={addproject}
              edit={edit}
              showInfo={showInfo}
              userId={userId}
              projInfo={editData}
              close={() => {
                setAddProject(false);
                setEditData({});
                setEdit(false);
                setShowInfo(false);
                setProjInfo([]);
              }}
              editData={editData}
              submitData={submitProject}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ProjectList;