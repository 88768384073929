export default function ProjectLoading() {
    return (
        <div
            >
            <div
                className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full project pr-2 animate-pulse">
                <div
                    className={`p-4 rounded animate__animated animate__fadeIn item-box h-36 bg-gray-300 dark:bg-gray-700`}></div>
                <div
                    className={`p-4 rounded animate__animated animate__fadeIn item-box h-36 bg-gray-300 dark:bg-gray-700 `}></div>
                <div
                    className={`p-4 rounded animate__animated animate__fadeIn item-box h-36 bg-gray-300 dark:bg-gray-700 `}></div>
                <div
                    className={`p-4 rounded animate__animated animate__fadeIn item-box h-36 bg-gray-300 dark:bg-gray-700 `}></div>
                <div
                    className={`p-4 rounded animate__animated animate__fadeIn item-box h-36 bg-gray-300 dark:bg-gray-700 `}></div>
                <div
                    className={`p-4 rounded animate__animated animate__fadeIn item-box h-36 bg-gray-300 dark:bg-gray-700 `}></div>
                <div
                    className={`p-4 rounded animate__animated animate__fadeIn item-box h-36 bg-gray-300 dark:bg-gray-700 `}></div>
                <div
                    className={`p-4 rounded animate__animated animate__fadeIn item-box h-36 bg-gray-300 dark:bg-gray-700 `}></div>

            </div>

        </div>
    )
}
